import { createContext, useContext, useState } from "react";
import { ModeloGetUrlJson } from "../ModeloCrud";

const CartillaContext = createContext();

export const CartillaProvider = ({ children }) => {
  const [cartillas, setCartillas] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [redes, setRedes] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [prestadores, setPrestadores] = useState([]);
  const [prestadoresOrdenados, setPrestadoresOrdenados] = useState([]);
  const [clientedSeleccionado, setClienteSeleccionado] = useState(null);
  const [cartillaSeleccionada, setCartillaSeleccionada] = useState(null);
  const [especialidadSeleccionada, setEspecialidadSeleccionada] =
    useState(null);
  const [redSeleccionada, setRedSeleccionada] = useState(null);
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(null);
  const [localidadSeleccionada, setLocalidadSeleccionada] = useState(null);
  const [coordenadas, setCoordenadas] = useState(null);
  const [ubicacion, setUbicacion] = useState({
    ciudad: "",
    provincia: "",
    pais: "",
  });
  const [direccion, setDireccion] = useState("");
  const [verificados, setVerificados] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [mensajeError, setMensajeError] = useState("");

  const buscarTablas = async () => {
    try {
      setIsLoading(true);
      const result = await ModeloGetUrlJson("cartilla", null, "tablas", null);
      setIsLoading(false);

      if (!result || result.error) {
        console.error("Error al cargar datos:", result);
        return;
      }
      console.log("Datos cargados:", result);

      // 📌 Verifica que los datos no sean undefined antes de asignarlos
      setCartillas(
        (result.cartillas || []).map((cart) => ({
          ...cart,
          cartilla: cart.cartilla
            ? cart.cartilla.trim().charAt(0).toUpperCase() +
              cart.cartilla.trim().slice(1).toLowerCase()
            : "",
        }))
      );
      setEspecialidades(result.especialidades || []);
      setRedes(result.redes || []);
      setProvincias(result.provincias || []);
      setLocalidades(
        (result.localidades || []).map((loc) => ({
          ...loc,
          localidad: loc.localidad
            ? loc.localidad.charAt(0).toUpperCase() +
              loc.localidad.slice(1).toLowerCase()
            : "",
        }))
      );
      setClientes(
        (result.clientes || []).map((cli) => ({
          ...cli,
          razon_social: cli.razon_social
            ? cli.razon_social.charAt(0).toUpperCase() +
              cli.razon_social.slice(1).toLowerCase()
            : "",
          denominacion_comercial: cli.denominacion_comercial
            ? cli.denominacion_comercial.charAt(0).toUpperCase() +
              cli.denominacion_comercial.slice(1).toLowerCase()
            : "",
        }))
      );
      setPlanes(result.planes || []);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setIsLoading(false);
    }
  };

  const handleBuscar = () => {
    setMensajeError("");

    if (!cartillaSeleccionada) {
      setMensajeError(
        "Debe seleccionar una cartilla para realizar la búsqueda."
      );
      return;
    }

    const query = new URLSearchParams();
    query.append("cartilla_id", cartillaSeleccionada.id);

    if (provinciaSeleccionada?.id)
      query.append("provincia_id", provinciaSeleccionada.id);
    if (especialidadSeleccionada?.id)
      query.append("especialidad_id", especialidadSeleccionada.id);
    if (redSeleccionada?.id) query.append("red_id", redSeleccionada.id);
    if (localidadSeleccionada?.id)
      query.append("localidad_id", localidadSeleccionada.id);
    if (verificados) query.append("verificados", 1);

    handleBuscarPrestadores(query.toString());
  };

  const handleBuscarPrestadores = async (paramString) => {
    console.log("Buscando prestadores con parámetros:", paramString);
    setPrestadores([]);
    try {
      setIsSearching(true);
      const result = await ModeloGetUrlJson(
        "cartilla",
        null,
        "vista-full",
        paramString
      );
      setIsSearching(false);
      console.log("Resultado de la búsqueda:", result);
      if (!result || result.error) {
        console.error("Error al cargar datos:", result);
        return;
      }
      setPrestadores(result.listado || []);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setIsSearching(false); // 👈 mejor que setIsLoading en este caso
    }
  };

  const resetContext = () => {
    setClientes([]);
    setCartillas([]);
    setPlanes([]);
    setEspecialidades([]);
    setRedes([]);
    setProvincias([]);
    setLocalidades([]);
    setPrestadores([]);
    setProvinciaSeleccionada(null);
    setLocalidadSeleccionada(null);
    setCoordenadas(null);
    setDireccion("");
    setMensajeError("");
    setClienteSeleccionado(null);
    setCartillaSeleccionada(null);
    setEspecialidadSeleccionada(null);
    setRedSeleccionada(null);
    setPrestadoresOrdenados([]);
    setVerificados(true);
    setUbicacion({ ciudad: "", provincia: "", pais: "" });
  };

  return (
    <CartillaContext.Provider
      value={{
        cartillas,
        setCartillas,
        especialidades,
        setEspecialidades,
        redes,
        setRedes,
        provincias,
        setProvincias,
        localidades,
        setLocalidades,
        clientes,
        setClientes,
        planes,
        setPlanes,
        prestadores,
        setPrestadores,
        prestadoresOrdenados,
        setPrestadoresOrdenados,
        clientedSeleccionado,
        setClienteSeleccionado,
        cartillaSeleccionada,
        setCartillaSeleccionada,
        especialidadSeleccionada,
        setEspecialidadSeleccionada,
        redSeleccionada,
        setRedSeleccionada,
        provinciaSeleccionada,
        setProvinciaSeleccionada,
        localidadSeleccionada,
        setLocalidadSeleccionada,
        coordenadas,
        setCoordenadas,
        ubicacion,
        setUbicacion,
        direccion,
        setDireccion,
        verificados,
        setVerificados,
        buscarTablas,
        isLoading,
        isSearching,
        handleBuscar,
        mensajeError,
        resetContext,
      }}
    >
      {children}
    </CartillaContext.Provider>
  );
};

export const useCartilla = () => useContext(CartillaContext);
